import { UserStatusLabel } from "@/constants/user/UserStatusLabel";
import { UserStatusEnum } from "@/enums/user/UserStatusEnum";

export const getUserStatus = (
  status: UserStatusEnum,
  isDeleted = false
): string => {
  if (isDeleted) {
    return "Deleted";
  }
  return UserStatusLabel[status];
};
