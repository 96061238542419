import { UserRoles } from "@/enums/UserRoles";

export const internalUserRoleSelect = [
  {
    label: "Super Admin",
    value: UserRoles.superAdmin,
  },
  {
    label: "Customer Service Admin",
    value: UserRoles.customerServiceAdmin,
  },
  {
    label: "Supply Chain Admin",
    value: UserRoles.purchaseTeamAdmin,
  },
  {
    label: "Account Manager",
    value: UserRoles.accountManager,
  },
  {
    label: "Costing Engineer",
    value: UserRoles.costingEngineer,
  },
  {
    label: "Quote Authorizer",
    value: UserRoles.quoteAuthorizer,
  },
  {
    label: "Quote Viewer",
    value: UserRoles.quoteViewer,
  },
  {
    label: "Engineering Team",
    value: UserRoles.engineeringTeam,
  },
  {
    label: "Technical Feedback Viewer",
    value: UserRoles.technicalFeedbackViewer,
  },
];
