import { TeamRoles } from "@/enums/TeamRoles";

export const userRoleSelect = [
  {
    label: "Member",
    value: TeamRoles.MEMBER,
  },
  {
    label: "Owner",
    value: TeamRoles.OWNER,
  },
];
